const config = {
  nodeEnv: process.env.NODE_ENV || "development",
  hammockDataMartApi: {
    endpoint: process.env.DATAMART_API_ENDPOINT || "",
    apiKey: process.env.DATAMART_API_APIKEY || ""
  },
  logApi: {
    endpoint: process.env.LOG_API_ENDPOINT || "",
    apiKey: process.env.LOG_API_APIKEY || ""
  },
  adminApi: {
    endpoint: process.env.ADMIN_API_ENDPOINT || ""
  },
  portalUrl: process.env.PORTAL_URL || "",
  aws: {
    region: process.env.AWS_REGION || "",
    cognitoUserPoolId: process.env.AWS_COGNITO_USER_POOL_ID || "",
    cognitoUserPoolWebClientId: process.env.AWS_COGNITO_USER_POOL_WEB_CLIENT_ID || ""
  },
  logLevel: process.env.LOGLEVEL || "error"
};

export default config;
